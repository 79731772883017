<template>
    <b-card class="card-custom">
        <p class="h5">VPN de Paris</p>
        <p>Afin de faciliter votre connexion au VPN de Paris, merci de suivre les étapes ci-dessous.</p>
        <b-list-group>
            <b-list-group-item button><strong>1)</strong> Télécharger le logiciel OpenVPN Connect <b-button class="btn-icon ml-3" size="sm" variant="light-primary" :href="link_soft" target="_blank"><i class="fas fa-download text-white"></i></b-button></b-list-group-item>
            <b-list-group-item button><strong>2)</strong> Installer le fichier télécharger en double cliquant dessus.</b-list-group-item>
            <b-list-group-item button><strong>3)</strong> Télécharger le fichier de configuration du VPN <b-button class="btn-icon ml-3" size="sm" variant="light-primary" @click="vpn"><i class="fas text-white" :class="load ? 'fa-spin fa-spinner' : 'fa-download'"></i></b-button></b-list-group-item>
            <b-list-group-item button><strong>4)</strong> Double cliquer dessus pour importer le fichier de configuration dans OpenVPN Connect.</b-list-group-item>
            <b-list-group-item button><strong>5)</strong> Cliquez sur connecter.</b-list-group-item>
        </b-list-group>
        <p class="font-weight-bold mt-3">Il est important de noter qu'à la fin de votre session, nous vous recommandons vivement de vous déconnecter.</p>        
        <div class="text-center">
            <b-button variant="light-danger" :href="link_video" target="_blank">                
                <span>Video tuto</span>&nbsp;&nbsp;<i class="fas fa-video"></i>
            </b-button>            
        </div>                
    </b-card>
</template>

<script>    
    export default {
        name: "RDP", 
        data(){
            return {
                load:false,
                link_soft:"https://openvpn.net/downloads/openvpn-connect-v3-windows.msi",
                link_video:"https://www.awesomescreenshot.com/video/21803717?key=f1d5fa48ea719cc83128de9a67d917e6"
            }
        },
        components: {            
        }, 
        beforeMount() {            
        },
        methods:{
            vpn(){
                if(this.load === true){
                    return false;
                }
                this.load = true;
                this.$api.ajax('/user/vpn', null, res => {
                    if(res.status){                        
                        var hiddenElement = document.createElement('a');
                        hiddenElement.href = 'data:text/plain;charset=utf-8,' + encodeURI(res.data.file);
                        hiddenElement.target = '_blank';
                        hiddenElement.download = res.data.file_name;
                        hiddenElement.click();                        
                    }
                    this.load = false
                })
            }
        }
    };
</script>
<style></style>